<template>
	<header class="ColorSkin">
		<div class="Container">
			<div class="Box">
				<Logo/>
			</div>
		</div>
	</header>
	<section class="SigninSection">
		<div class="Container">
			<div class="Box">
				<h1>Registrazione</h1>
				<div class="TxtEditor">
					<!--<p>Inserire qui testo istruzioni registrazione</p>-->
				</div>
			</div>
			<div class="Box">
				<form class="Form">
					<fieldset>
						<legend>Dati utente</legend>
						<div class="FormRow">
							<div class="FormBox50">
								<label>Nome</label>
								<input type="text" id="NOME_UTENTE" v-model="NOME_UTENTE">
							</div>
							<div class="FormBox50">
								<label>Cognome</label>
								<input type="text" id="COGNOME_UTENTE" v-model="COGNOME_UTENTE">
							</div>
						</div>
						<div class="FormRow">
							<div class="FormBox50">
								<label>Email</label>
								<input type="text" id="EMAIL_UTENTE" v-model="EMAIL_UTENTE">
							</div>
							<div class="FormBox50">
								<label>Codice Fiscale</label>
								<input type="text" id="COD_FIS_UTENTE" v-model="COD_FIS_UTENTE">
							</div>
						</div>
						<div class="FormRow">
							<div class="FormBox50">
								<label>Telefono</label>
								<input type="text" id="TELEFONO_UTENTE" v-model="TELEFONO_UTENTE">
							</div>
						</div>
					</fieldset>
					<fieldset>
						<div class="PopperHolder">
							<legend>Dati Tesserato 1</legend>
							<legend>
								<Popper>
									<a class="PopUp">i</a>                        
									<template #content>
										<p>Utilizza questa form per inserire i dati del Tesserato!</p>
										<p>Puoi connettere al tuo account tutti i Tesserati di cui necessiti, ricordati di salvare i tuoi dati!</p>
									</template>
								</Popper>
							</legend>
						</div>
						<div class="FormRow">
							<div class="FormBox50">
								<label>Nome</label>
								<input :disabled="this.disableInput" type="text" id="NOME" v-model="NOME" >
							</div>
							<div class="FormBox50">
								<label>Cognome</label>
								<input :disabled="this.disableInput" type="text" id="COGNOME" v-model="COGNOME">
							</div>
						</div>
						<div class="FormRow">
							<div class="FormBox50">
								<label>Data di nascita</label><label>(se minorenne saranno richiesti i dati del genitore)</label>
								<input :disabled="this.disableInput" type="date" v-model="DATA_NASCITA_TESSERATO" id="DATA_NASCITA_TESSERATO">
							</div>
							<div class="FormBox50">
								<label>Anno</label>
								<select :disabled="this.disableInput" class="CustomSelect" v-model="CATEGORIA_TESSERATO" id="CATEGORIA_TESSERATO">
									<option selected="selected" value="">Seleziona l'anno</option>
									<option v-for="categoria in this.listaCategorie" :key="categoria?.Codice" :value="categoria?.Codice">{{categoria?.Descrizione}}</option>
								</select>
							</div>
							<div class="FormBox50">
								<label>Seleziona la società</label>
								<select :disabled="this.disableInput" class="CustomSelect" v-model="SOCIETA_TESSERATO" id="SOCIETA_TESSERATO">
									<option selected="selected" value="">Seleziona la società</option>
									<option v-for="societa in this.listaSocieta" :key="societa?.CodiceAzienda" :value="societa?.CodiceAzienda">{{societa?.Descrizione}}</option>
								</select>
							</div>
						</div>
						<fieldset v-if="ETA < 18">
							<legend>Dati genitore di {{this.NOME}}</legend>
							<div class="FormRow">
								<div class="FormBox50">
									<label>Nome</label>
									<input :disabled="this.disableInput" type="text" id="NOME_GENITORE" name="NOME_GENITORE" placeholder="" v-model="NOME_GENITORE">
								</div>
								<div class="FormBox50">
									<label>Cognome</label>
									<input :disabled="this.disableInput" type="text" id="COGNOME_GENITORE" name="COGNOME_GENITORE" placeholder="" v-model="COGNOME_GENITORE">
								</div>
							</div>
							<div class="FormRow">
								<div class="FormBox50">
									<label>Data di nascita</label>
									<input :disabled="this.disableInput" type="date" id="DATA_NASCITA_GENITORE" name="DATA_NASCITA_GENITORE" placeholder="" v-model="DATA_NASCITA_GENITORE">
								</div>
								<!--<div class="FormBox50">
									<label>Telefono</label>
									<input :disabled="this.disableInput" type="text" id="TELEFONO_GENITORE" name="TELEFONO_GENITORE" placeholder="" v-model="TELEFONO_GENITORE">
								</div>-->
							</div>
						</fieldset>
						<FormTesserato
							v-for="(tesserato,index) in this.listaTesserati" :key="index"
							:tesserato="tesserato"
							:index="index"
							@updateField="this.updateTesserato($event);"
							@deleteTesserato="this.deleteTesseratoTmp($event)"
						/>
						<div class="AddNewMemberBox">
							<div ref="toggle" class="AddNewMemberField ToggleBox">
								<fieldset>
									<legend>Dati nuovo tesserato</legend>
									<div class="FormRow">
										<div class="FormBox50">
											<label>Nome</label>
											<input :disabled="this.disableInput" type="text" placeholder="Nome" v-model="this.newTNome">
										</div>
										<div class="FormBox50">
											<label>Cognome</label>
											<input :disabled="this.disableInput" type="text" placeholder="Cognome" v-model="this.newTCognome">
										</div>
									</div>
									<div class="FormRow">
										<div class="FormBox50">
											<label>Data di nascita</label>
											<input :disabled="this.disableInput" type="date" id="DATA_NASCITA_TESSERATO_2" v-model="this.newTDtNascita">
										</div>
										<div class="FormBox50">
											<label>Anno</label>
											<select :disabled="this.disableInput" class="CustomSelect" v-model="this.newTCategoria">
												<option selected="selected" value="">Seleziona l'anno</option>
												<option v-for="categoria in this.listaCategorie" :key="categoria?.Codice" :value="categoria?.Codice">{{categoria?.Descrizione}}</option>
											</select>
										</div>
									</div>
									<div class="FormRow">
										<div class="FormBox50">
											<label>Seleziona la tua societa</label>
											<select :disabled="this.disableInput" class="CustomSelect" v-model="this.newTSocieta">
												<option selected="selected" value="">Seleziona la società</option>
												<option v-for="societa in this.listaSocieta" :key="societa?.CodiceAzienda" :value="societa?.CodiceAzienda">{{societa?.Descrizione}}</option>
											</select>
										</div>
									</div>
									<fieldset v-if="this.newEta < 18">
										<legend>Dati genitore</legend>
										<div class="FormRow">
											<div class="FormBox50">
												<label>Nome</label>
												<input :disabled="this.disableInput" type="text" id="NOME_GENITORE" name="NOME_GENITORE_2" placeholder="" v-model="NOME_GENITORE_2">
											</div>
											<div class="FormBox50">
												<label>Cognome</label>
												<input :disabled="this.disableInput" type="text" id="COGNOME_GENITORE" name="COGNOME_GENITORE_2" placeholder="" v-model="COGNOME_GENITORE_2">
											</div>
										</div>
										<div class="FormRow">
											<div class="FormBox50">
												<label>Data di nascita</label>
												<input :disabled="this.disableInput" type="date" id="DATA_NASCITA_GENITORE" name="DATA_NASCITA_GENITORE_2" placeholder="" v-model="DATA_NASCITA_GENITORE_2">
											</div>
											<!--<div class="FormBox50">
												<label>Telefono</label>
												<input :disabled="this.disableInput" type="text" id="TELEFONO_GENITORE_2" name="TELEFONO_GENITORE_2" placeholder="" v-model="TELEFONO_GENITORE_2">
											</div>-->
										</div>
									</fieldset>
									<div class="FormBox50 TwinButtons">
										<div class="Button">
											<input type="button" value="Annulla" @click="this.clearTesserato">
										</div>
										<div class="Button">
											<input :disabled="this.disableInput" type="button" value="Aggiungi" @click="this.addTesserato">
										</div>
									</div>
									<p style="color: #e13b30;"> * Prima di proseguire premi il tasto aggiungi!</p>
								</fieldset>
							</div>
							<div v-if="addeddTesseratoUno" class="AddNewMemberOpenButton ToggleButton" @click="this.presetData"><span>Aggiungi nuovo tesserato</span></div>
						</div>
					</fieldset>
					<div class="WhiteSpace">&nbsp;</div>
					<div class="FormRow">
						<div class="FormBoxWide">
							<div class="FormCheckBox" id="CONSENSO_PRIVACY">
								<div class="CheckField"><input type="checkbox" name="" v-model="CONSENSO_PRIVACY" /><span class="Checkmark"></span></div>
								<div class="CheckLabel">Acconsento i <a class="" href="#/pagine/termini-e-condizioni" target="_blank">Termini e le nostre Condizioni</a> e confermo di aver letto la  <a class="" href="#/pagine/privacy-policy" target="_blank">Privacy Policy</a> *</div>
							</div>
							<div class="FormCheckBox">
								<div class="CheckField"><input type="checkbox" name="" v-model="CONSENSO_MARKETING" /><span class="Checkmark"></span></div>
								<div class="CheckLabel">Acconsento al trattamento dei miei dati personali per le finalità commerciali e di marketing come indicato nella <a class="" href="#/pagine/privacy-policy" target="_blank">Privacy Policy</a></div>
							</div>
						</div>
					</div>
					<div class="WhiteSpace">&nbsp;</div>
					<div class="FormRow">
						<div class="FormBox50">
							<div class="Button" :class="{ Disabled:(this.SubmitFormBtn == false )}">
								<input v-if="this.SubmitFormBtn" type="button" value="Registrati" @click="submitRegistration($event)">
								<input v-else :class="{ Disabled:(this.SubmitFormBtn == false )}" type="button" value="Registrati">
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</section>
	<Footer/>
</template>

<script>
import Logo from '@/components/Logo.vue';
import FormTesserato from '@/components/FormTesserato.vue';
import Footer from '@/components/Footer.vue';
import { apiMixin } from "@/mixin.js";

export default {
	name: "Registrazione",
	mixins:[apiMixin],
	data() {
		return {
			// DATI UTENTE
			NOME_UTENTE: "",
			COGNOME_UTENTE: "",
			EMAIL_UTENTE: "",
			COD_FIS_UTENTE: "",
			TELEFONO_UTENTE: "",

			//DATI TESSERATO
			NOME: "",
			COGNOME: "",
			DATA_NASCITA_TESSERATO: "",
			ANNO_NASCITA_TESSERATO: "",
			CATEGORIA_TESSERATO: "",
			SOCIETA_TESSERATO: "",

			//DATI NUOVO TESSERATO
			newTNome: "",
			newTCognome: "",
			newTDtNascita: "",
			newTCategoria: "",
			newTSocieta: "",
			newEta: 18,

			//DATI GENITORE
			NOME_GENITORE: "",
			COGNOME_GENITORE: "",
			NOME_GENITORE_2: "",
			COGNOME_GENITORE_2: "",
			DATA_NASCITA_GENITORE: "",
			DATA_NASCITA_GENITORE_2:"",
			ANNO_NASCITA_GENITORE: "",
			EMAIL: "",
			//TELEFONO_GENITORE_2: "",
			//TELEFONO_GENITORE: "",
			
			CONSENSO_MARKETING: "",
			CONSENSO_PRIVACY: "",
			ETA: 18,
			listaTesserati:[],
			index:0,
			SubmitFormBtn: true,
			disableInput: true
		}
	},
	components: {
		Logo,
		FormTesserato,
		Footer
	},
	computed:{
		listaSocieta: function(){
			return this.$store.getters.getSocieta;
		},
		listaCategorie:function(){
			return this.$store.getters.getCategorie;
		},
		addeddTesseratoUno: function(){
			if(this.NOME == "" || this.COGNOME == ""  || this.DATA_NASCITA_TESSERATO == "" || this.SOCIETA_TESSERATO == ""){
				return false;
			}else{
				return true;
			}
		}
	},
	mounted: function(){
		$(".ToggleBox").hide();
		$(".ToggleButton").addClass('Open');
		$(".ToggleButton").click(function(){
			$(this).parent().find(".ToggleBox").slideToggle('fast');
			$(this).toggleClass('Open').toggleClass('Close');
		}); 
		this.$store.dispatch({
            type: 'getCategorieCalcisticheFromApi'
        });
		this.$store.dispatch({
			type: 'getSocietaCalcistiche'
		});
		this.$store.dispatch({
			type: 'setLoader',
			value: false
		});
	},
	watch:	{
		DATA_NASCITA_TESSERATO: {
			handler: function(val, oldVal) {
				this.inputError(val, 'DATA_NASCITA_TESSERATO');
				var today = new Date();
				var birthDate = new Date(val);
				var age = (today.getFullYear() - birthDate.getFullYear());
				var m = today.getMonth() - birthDate.getMonth();
				if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
					age--;
				}
				this.ETA = age;
				this.ANNO_NASCITA_TESSERATO = birthDate.getFullYear();
				this.NOME_GENITORE_2 = this.NOME_GENITORE;
				this.COGNOME_GENITORE_2 = this.NOME_GENITORE;
				this.DATA_NASCITA_GENITORE_2 = this.DATA_NASCITA_GENITORE;
				//this.TELEFONO_GENITORE = this.TELEFONO_GENITORE;
				return age;
			},
			deep: true
		},
		newTDtNascita:{
			handler: function(val, oldVal) {
				this.inputError(val, 'DATA_NASCITA_TESSERATO_2');
				this.newEta = this.calcolaEta(val);
			},
			deep: true
		},

		// Contorlli Utente
		CONSENSO_PRIVACY: function(val, oldVal){
			this.inputError(val, 'CONSENSO_PRIVACY');			
		},
		NOME_UTENTE: function(val, oldVal){
			this.inputError(val, 'NOME_UTENTE');
		},
		COGNOME_UTENTE: function(val, oldVal){
			this.inputError(val, 'COGNOME_UTENTE');
		},
		EMAIL_UTENTE: function(val, oldVal){
			this.inputError(val, 'EMAIL_UTENTE');
		},
		TELEFONO_UTENTE: function(val,oldVal){
			this.inputError(val, 'TELEFONO_UTENTE')
		},
		COD_FIS_UTENTE: function(val, oldVal){
			document.getElementById('COD_FIS_UTENTE').classList.add('errorBorder');
			let age = this.calcolaEtaDaCF();
			if(!isNaN(age)){
				if(age < 18){
					this.$toast.error("L'Utente deve essere maggiorenne!");
					this.SubmitFormBtn = false;
					this.disableInput = true;
				}else{
					this.SubmitFormBtn = true;
					document.getElementById('COD_FIS_UTENTE').classList.remove('errorBorder');
					this.disableInput = false;
				}
			}
		},

		//Controlli Tesserato 1
		NOME: function(val, oldVal){
			this.inputError(val, 'NOME');			
		},
		COGNOME: function(val, oldVal){
			this.inputError(val, 'COGNOME');			
		},
		CATEGORIA_TESSERATO: function(val, oldVal){
			this.inputError(val, 'CATEGORIA_TESSERATO');			
		},
		SOCIETA_TESSERATO: function(val, oldVal){
			this.inputError(val, 'SOCIETA_TESSERATO');			
		},

		//Controlli tesserato 1 se minorenne
		NOME_GENITORE: function(val, oldVal){
			if(this.ETA < 18){
				this.inputError(val, 'NOME_GENITORE');
			}			
		},
		COGNOME_GENITORE: function(val, oldVal){
			if(this.ETA < 18){
				this.inputError(val, 'COGNOME_GENITORE');
			}			
		},
		DATA_NASCITA_GENITORE: function(val, oldVal){
			if(this.ETA < 18){
				this.inputError(val, 'DATA_NASCITA_GENITORE');
			}			
		},
		/*TELEFONO_GENITORE: function(val, oldVal){
			if(this.ETA < 18){
				this.inputError(val, 'TELEFONO_GENITORE');
			}			
		}*/
	},
	methods: {
		clearTesserato: function(){
			this.newTNome = "";
			this.newTCognome = "";
			this.newTDtNascita = "";
			this.newTCategoria = "";
			this.newTSocieta = "";
			this.NOME_GENITORE_2 = "";
			this.COGNOME_GENITORE_2 = "";
			this.DATA_NASCITA_GENITORE_2 = "";
			//this.TELEFONO_GENITORE_2 = "";

			$(this.$refs.toggle).parent().find(".ToggleBox").slideToggle('fast');
			$(this.$refs.toggle).toggleClass('Open').toggleClass('Close');
	
		},
		calcolaEta: function(val){
			var today = new Date();
			var birthDate = new Date(val);
			var age = (today.getFullYear() - birthDate.getFullYear());
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age;
		},
		addTesserato: function(){
			this.index++;
			var birthDate = new Date(this.DATA_NASCITA_TESSERATO).getFullYear();
			this.listaTesserati.push({
				NOME_TESSERATO: this.newTNome,
				COGNOME_TESSERATO: this.newTCognome,
				DATA_NASCITA_TESSERATO: this.newTDtNascita,
				ANNO_NASCITA_TESSERATO: birthDate,
				CATEGORIA_TESSERATO: this.newTCategoria,
				SOCIETA_TESSERATO: this.newTSocieta,
				DATA_NASCITA_GENITORE: this.DATA_NASCITA_GENITORE_2,
				NOME_GENITORE: this.NOME_GENITORE_2,
				COGNOME_GENITORE: this.COGNOME_GENITORE_2,
				//TELEFONO_GENITORE: this.TELEFONO_GENITORE_2,
				INDEX: this.index
			});
			this.newTNome = "";
			this.newTCognome = "";
			this.newTDtNascita ="";
			this.newTCategoria = "";
			this.newTSocieta = "";
			$(this.$refs.toggle).parent().find(".ToggleBox").slideToggle('fast');
			$(this.$refs.toggle).toggleClass('Open').toggleClass('Close');
	
		},	
		updateTesserato: function(ev){
			let idx = this.listaTesserati.findIndex( x =>{ 
				return x.INDEX == ev.INDEX
			});
			if(idx > -1){
				this.listaTesserati[idx].NOME = ev.NOME;
				this.listaTesserati[idx].COGNOME = ev.COGNOME;
				this.listaTesserati[idx].DT_NASCITA = ev.DT_NASCITA;
				this.listaTesserati[idx].CATEGORIA = ev.CATEGORIA;
				this.listaTesserati[idx].SOCIETA = ev.SOCIETA;
				this.listaTesserati[idx].NOME_GENITORE = ev.NOME_GENITORE;
				this.listaTesserati[idx].COGNOME_GENITORE = ev.COGNOME_GENITORE;
				//this.listaTesserati[idx].TELEFONO_GENITORE = ev.TELEFONO_GENITORE;
				this.listaTesserati[idx].DATA_NASCITA_GENITORE = ev.DATA_NASCITA_GENITORE;
			}
		},
		inputError: function(val, id){
			if(val){
				document.getElementById(id).classList.remove('errorBorder');
			}
			else{
				document.getElementById(id).classList.add('errorBorder');
			}
		},
		
		checkForm: function (e) {
			e.preventDefault();
			let check = true;
			// Controlli su dati utente
			if(!this.EMAIL_UTENTE){
				document.getElementById('EMAIL_UTENTE').classList.add('errorBorder');
				check = check && false;
			}
			if(!this.NOME_UTENTE){
				document.getElementById('NOME_UTENTE').classList.add('errorBorder');
				check = check && false;
			}
			if(!this.COGNOME_UTENTE){
				document.getElementById('COGNOME_UTENTE').classList.add('errorBorder');
				check = check && false;
			}
			if(!this.TELEFONO_UTENTE){
				document.getElementById('TELEFONO_UTENTE').classList.add('errorBorder');
				check = check && false;
			}
			if(!this.COD_FIS_UTENTE){
				document.getElementById('COD_FIS_UTENTE').classList.add('errorBorder');
				check = check && false;
			}else{
				let age = this.calcolaEtaDaCF();
				if(age < 18){
					this.$toast.error("L'utente deve essere maggiorenne!",{position:"top-right"});
				}
			}	

			// Controlli sui dati del tesserato 1
			if(!this.NOME){
				document.getElementById('NOME').classList.add('errorBorder');
				check = check && false;
			}
			if(!this.COGNOME){
				document.getElementById('COGNOME').classList.add('errorBorder');
				check = check && false;
			}
			if(!this.DATA_NASCITA_TESSERATO){
				document.getElementById('DATA_NASCITA_TESSERATO').classList.add('errorBorder');
				check = check && false;
			}
			if(!this.CATEGORIA_TESSERATO){
				document.getElementById('CATEGORIA_TESSERATO').classList.add('errorBorder');
				check = check && false;
			}
			if(!this.SOCIETA_TESSERATO){
				document.getElementById('SOCIETA_TESSERATO').classList.add('errorBorder');
				check = check && false;
			}

			// Controlli sui dati del genitore se il tesserato 1 è minorenne
			if(this.ETA < 18){
				if(!this.NOME_GENITORE){
					document.getElementById('NOME_GENITORE').classList.add('errorBorder');
					check = check && false;
				}
				if(!this.COGNOME_GENITORE){
					document.getElementById('COGNOME_GENITORE').classList.add('errorBorder');
					check = check && false;
				}
				if(!this.DATA_NASCITA_GENITORE){
					document.getElementById('DATA_NASCITA_GENITORE').classList.add('errorBorder');
					check = check && false;
				}
				/*if(!this.TELEFONO_GENITORE){
					document.getElementById('TELEFONO_GENITORE').classList.add('errorBorder');
					check = check && false;
				}*/
			}

			// Controllo Privacy
			if(!this.CONSENSO_PRIVACY){
				document.getElementById('CONSENSO_PRIVACY').classList.add('errorBorder');
				check = check && false;
			}
			return check;
		},
		CreateMapMesi:function(){
			let mesi = new Map();
			mesi.set("A","00");
			mesi.set("B","01");
			mesi.set("C","02");
			mesi.set("D","03");
			mesi.set("E","04");
			mesi.set("H","05");
			mesi.set("L","06");
			mesi.set("M","07");
			mesi.set("P","08");
			mesi.set("R","09");
			mesi.set("S","10");
			mesi.set("T","11");
			return mesi;
		},
		submitRegistration(e) {
			if(!this.checkForm(e)){
				return;
			}
			this.$store.dispatch({
				type:'setLoader',
				value: true
			});
			let newList = [];
			if(this.NOME != "" && this.COGNOME != "" && this.DATA_NASCITA_TESSERATO != "" && this.SOCIETA_TESSERATO != ""){
				var idx = this.listaTesserati.findIndex(x =>{
					return x.NOME_TESSERATO == this.NOME &&
							x.COGNOME_TESSERATO == this.COGNOME && 
							x.DATA_NASCITA_TESSERATO == this.DATA_NASCITA_TESSERATO;
				});
				if(idx == -1){
					var birthDate = new Date(this.DATA_NASCITA_TESSERATO).getFullYear();
					newList = this.listaTesserati.map((x) => x);
					newList.push({
						NOME_TESSERATO: this.NOME,
						COGNOME_TESSERATO: this.COGNOME,
						ANNO_NASCITA_TESSERATO: birthDate,
						DATA_NASCITA_TESSERATO:this.DATA_NASCITA_TESSERATO != "" ? this.DATA_NASCITA_TESSERATO : null,
						CATEGORIA_TESSERATO: this.CATEGORIA_TESSERATO,
						SOCIETA_TESSERATO: this.SOCIETA_TESSERATO,
						NOME_GENITORE: this.NOME_GENITORE,
						COGNOME_GENITORE: this.COGNOME_GENITORE,
						DATA_NASCITA_GENITORE: this.DATA_NASCITA_GENITORE != "" ? this.DATA_NASCITA_GENITORE : null,
						//TELEFONO_GENITORE: this.TELEFONO_GENITORE
					});
				}
			}
			let payload = {
				NOME_UTENTE: this.NOME_UTENTE,
				COGNOME_UTENTE: this.COGNOME_UTENTE,
				EMAIL_UTENTE: this.EMAIL_UTENTE,
				TELEFONO_UTENTE: this.TELEFONO_UTENTE,
				COD_FIS_UTENTE: this.COD_FIS_UTENTE,
				TESSERATI: newList
			};
			this.doApiCall("postNoAuth", "b2c/Utenti", null, null, payload).then(
				(data) =>{
					this.$store.dispatch({
						type:'setLoader',
						value: false
					});
					if(data){
						if(data.StatusCode == 200){
							if(data.Data.status == "400"){
								if(data.Data.msgInsUtente == 'DuplicateUserName'){
									this.$toast.error("Utente gia esistente, contattare l'assistenza");
									this.$router.push("/");
								}
							}else if(data.Data.msgInsTesserato == ""){
									this.$toast.error("Tesserato già esistente, contattare l'assistenza");
									this.$router.push("/");
							}else{
								this.$router.replace({ path: '/registrati/grazie' });
							}
						}else{
							this.$toast.error("Errore registrazione utente, contattare l'assistenza");
						}
					}
				}
			);
		},
		calcolaEtaDaCF: function(){
			let aa =this.COD_FIS_UTENTE[6]+this.COD_FIS_UTENTE[7];
			let mesi = this.CreateMapMesi() 
			let m = mesi.get(this.COD_FIS_UTENTE[8]);
			let gg = parseInt(this.COD_FIS_UTENTE[9]+this.COD_FIS_UTENTE[10]);
			if(gg > 40){
				gg -= 40; 
			}
			let year = '00';
			if(parseInt(aa) <= 35 ){
				year = '20'+aa;
			}else{
				year = '19'+aa;
			}
			let dtNascitaUtente = new Date(year,m,gg);
			var today = new Date();
			return (today.getFullYear() - dtNascitaUtente.getFullYear());
		},
		presetData: function(){
			$(this.$refs.toggle).parent().find(".ToggleBox").slideToggle('fast');
			$(this.$refs.toggle).toggleClass('Open').toggleClass('Close');
			this.NOME_GENITORE_2 = this.NOME_GENITORE;
			this.COGNOME_GENITORE_2 = this.COGNOME_GENITORE;
			this.DATA_NASCITA_GENITORE_2 = this.DATA_NASCITA_GENITORE;
			//this.TELEFONO_GENITORE_2 = this.TELEFONO_GENITORE;
		},
		deleteTesseratoTmp:function(ev){
			this.$store.dispatch({
				type: 'setLoader',
				value:true
			});
			setTimeout(
				() =>{
					this.doFunction(ev);
					this.$store.dispatch({
						type: 'setLoader',
						value:false
					})
				}
				,500);
		},
		doFunction: function(ev){
			if(ev != null && this.listaTesserati != null){
				let idx = this.listaTesserati.findIndex(el =>{
					return el.NOME_TESSERATO == ev.NOME && el.COGNOME_TESSERATO == ev.COGNOME && el.DATA_NASCITA_TESSERATO == ev.DT_NASCITA && el.SOCIETA_TESSERATO == ev.SOCIETA;
				});
				if (idx > -1){
					this.listaTesserati.splice(idx,1);
				}
			}
		}
	}
}
</script>
<style>
.errorBorder{
	border: 2px solid #ff0000 !important;
}

:root {
    --popper-theme-background-color: #ffffff;
    --popper-theme-background-color-hover:	#ffffff;
    --popper-theme-text-color:	inherit;
    --popper-theme-border-width:	1px;
    --popper-theme-border-style:	solid;
    --popper-theme-border-color:	#e10712;
    --popper-theme-border-radius:	6px;
    --popper-theme-padding:	16px;
    --popper-theme-box-shadow:	0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
.Disabled{
	background-color: grey !important;
	background-image: linear-gradient(grey,grey) !important;
}
</style>
