import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL;

class MenuService{

    getMenuByTipo(token, tipo, lingua, codShop){

        return axios({
            method: 'get',
            url: API_URL + codShop +'/Menu/GetMenuByTipo', 
            params:{
                tipo: tipo,
                lingua: lingua,
                codShop: codShop
            },           
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }
        }).then(data =>  {            
            if(data.status && data.status == 401){
                return false;
            }
            return data.data
        });
    }
}

export default new MenuService();