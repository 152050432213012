<template>
    <fieldset>
        <legend>Dati Tesserato {{this.newIndex}}
            <Popper @click="this.delete">
                <a><img src="/images/DeleteIcon.png" srcset="/images/DeleteIconRetina.png 2x" alt="Rimuovi"/></a>
            </Popper>
        </legend>
        <div class="FormRow">
            <div class="FormBox50">
                <label>Nome</label>
                <input type="text" id="NOME" v-model="this.newName" @focusout="this.updateEvent()">
            </div>
            <div class="FormBox50">
                <label>Cognome</label>
                <input type="text" id="COGNOME" v-model="this.newCognome">
            </div>
        </div>
        <div class="FormRow">
            <div class="FormBox50">
                <label>Data di nascita</label><label>(se minorenne saranno richiesti i dati del genitore)</label>
                <input type="date" id="DT_NASCITA" v-model="this.newDtNascita">
            </div>
            <div class="FormBox50">
                <!-- Inserire chiamata web api per get categorie-->
                <label>Anno</label>
                <select class="CustomSelect" id="CATEGORIA" v-model="this.newCategoria">
                    <option selected="selected" value="">Seleziona l'anno</option>
					<option v-for="categoria in this.listaCategorie" :key="categoria?.Codice" :value="categoria?.Codice">{{categoria?.Descrizione}}</option>
                </select>
            </div>
        </div>
        <div class="FormRow">
            <div class="FormBox50">
                <!-- Inserire chiamata web api per get squadre-->
                <label>Seleziona la tua societa</label>
                <select class="CustomSelect" id="SOCIETA" v-model="this.newSocieta">
                    <option selected="selected" value="">Seleziona la società</option>
				    <option v-for="societa in this.listaSocieta" :key="societa?.CodiceAzienda" :value="societa?.CodiceAzienda">{{societa?.Descrizione}}</option>
                </select>
            </div>
        </div>
    </fieldset>
    <fieldset v-if="this.age < 18">
        <legend>Dati genitore di {{this.newName}}</legend>
        <div class="FormRow">
            <div class="FormBox50">
                <label>Nome</label>
                <input type="text" id="NOME_GENITORE" name="NOME_GENITORE" placeholder="" v-model="nomeGenitore">
            </div>
            <div class="FormBox50">
                <label>Cognome</label>
                <input type="text" id="COGNOME_GENITORE" name="COGNOME_GENITORE" placeholder="" v-model="cognomeGenitore">
            </div>
        </div>

        <div class="FormRow">
            <div class="FormBox50">
                <label>Data di nascita</label>
                <input type="date" id="DATA_NASCITA_GENITORE" name="DATA_NASCITA_GENITORE" placeholder="" v-model="dataNascitaGenitore">
            </div>
            <!--<div class="FormBox50">
                <label>Telefono</label>
                <input type="text" id="TELEFONO_GENITORE" name="TELEFONO_GENITORE" placeholder="" v-model="telefonoGenitore">
            </div>-->
        </div>
    </fieldset>
</template>
<script>
import {errorMixin} from '@/mixin.js'
export default {
    name:"FormTesserato",
    props:{
        tesserato:Object,
        index: Number
    },
    emits:['updateField','deleteTesserato'],
    mixins:[errorMixin],
    data(){
        return {
            newName:"",
            newCognome: "",
            newDtNascita: "",
            newCategoria: "",
            newSocieta: "",
            newIndex: 0,
            age: 18,
            nomeGenitore: "",
            cognomeGenitore: "",
            dataNascitaGenitore: "",
            //telefonoGenitore: "",
            Emit: false
        }
    },
    watch:{
        newName: function(val){
            this.inputError(val, 'NOME');	
        },
        newCognome: function(val){
            this.inputError(val, 'COGNOME');	
        },
        newDtNascita: function(val){
            this.inputError(val, 'DT_NASCITA');	
        },
        newCategoria: function(val){
            this.inputError(val, 'CATEGORIA');	
        },
        newSocieta: function(val){
            this.inputError(val, 'SOCIETA');	
        },
        nomeGenitore :function(val){
            this.inputError(val, 'NOME_GENITORE');	
        },
        cognomeGenitore:function(val){
            this.inputError(val, 'COGNOME_GENITORE');	
        },
        dataNascitaGenitore: function(val){
            this.inputError(val, 'DATA_NASCITA_GENITORE');	
        },
        /*telefonoGenitore: function(val){
            this.inputError(val, 'TELEFONO_GENITORE');	
        }*/
    },
    mounted: function(){
        this.newIndex = this.index + 2;
        this.newName = this.tesserato?.NOME_TESSERATO;
        this.newCognome = this.tesserato?.COGNOME_TESSERATO;
        this.newDtNascita = this.tesserato?.DATA_NASCITA_TESSERATO;
        this.newCategoria = this.tesserato?.CATEGORIA_TESSERATO;
        this.newSocieta = this.tesserato?.SOCIETA_TESSERATO;
        this.nomeGenitore = this.tesserato?.NOME_GENITORE;
        this.cognomeGenitore = this.tesserato?.COGNOME_GENITORE;
        this.dataNascitaGenitore = this.tesserato?.DATA_NASCITA_GENITORE;
        //this.telefonoGenitore = this.tesserato?.TELEFONO_GENITORE;
        var today = new Date();
        var birthDate = new Date(this.tesserato?.DATA_NASCITA_TESSERATO);
        this.age = (today.getFullYear() - birthDate.getFullYear());
    },
    methods:{
        inputError: function(val, id){
			if(val){
				document.getElementById(id).classList.remove('errorBorder');
                this.Emit = true;
			}
			else{
				document.getElementById(id).classList.add('errorBorder');
                this.Emit = false
			}
		},
        updateEvent: function(){
            if(this.Emit){
                this.$emit("updateField",{
                    NOME: this.newName,
                    COGNOME: this.newCognome,
                    DT_NASCITA: this.newDtNascita,
                    CATEGORIA: this.newCategoria,
                    SOCIETA: this.newSocieta,
                    NOME_GENITORE: this.nomeGenitore,
                    COGNOME_GENITORE: this.cognomeGenitore,
                    DATA_NASCITA_GENITORE: this.dataNascitaGenitore,
                    //TELEFONO_GENITORE: this.telefonoGenitore,
                    INDEX: this.index -  2
                });
            }
        },
        delete: function(){
            this.$emit("deleteTesserato",{
                NOME: this.newName,
                COGNOME: this.newCognome,
                DT_NASCITA: this.newDtNascita,
                CATEGORIA: this.newCategoria,
                SOCIETA: this.newSocieta
            });
        }
    },
    computed:{
        listaSocieta: function(){
			return this.$store.getters.getSocieta;
		},
        listaCategorie:function(){
			return this.$store.getters.getCategorie;
		}
    }
}
</script>

<style>
.errorBorder{
	border: 2px solid #ff0000 !important;
}

:root {
    --popper-theme-background-color: #ffffff;
    --popper-theme-background-color-hover:	#ffffff;
    --popper-theme-text-color:	inherit;
    --popper-theme-border-width:	1px;
    --popper-theme-border-style:	solid;
    --popper-theme-border-color:	#e10712;
    --popper-theme-border-radius:	6px;
    --popper-theme-padding:	16px;
    --popper-theme-box-shadow:	0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
.Disabled{
	background-color: grey !important;
	background-image: linear-gradient(grey,grey) !important;
}
</style>
