<template>
    <!-- Inizio footer -->
<footer>
    <div class="Container">
        <div class="Box">
            <div class="Binder Big">
                <div class="FooterAddressBox">
                    <div class="FooterLogo"><a href="/"><img src="images/WhiteLogo.png" srcset="images/WhiteLogoRetina.png 2x" alt="Studio Sport" /></a></div>
                    <address>
                        <p><strong>STUDIO SPORT srl</strong></p>
                        <p>Via di Tempagnano 180, 55100 LUCCA</p>
                        <p>P.I. e C.F 02121180463 © Copyright 2022</p>
                    </address>
                </div>
                <div class="FooterMenuBox">
                    <div class="FooterMenu">
                        <h3 style="text">Aiuto</h3>
                        <ul>
                            <li><a href="#/pagine/termini-e-condizioni">Termini e condizioni</a></li>
                            <li><a href="#/pagine/privacy-policy">Privacy policy</a></li>
                        </ul>
                    </div>
                </div>
                <!--<div class="SocialMenu">
                    <h3>Seguici su:</h3>
                    <ul>
                        <li><a href="#"><img src="images/FacebookIcon.png" srcset="images/FacebookIconRetina.png 2x" alt="Facebook" /></a></li>
                        <li><a href="#"><img src="images/TwitterIcon.png" srcset="images/TwitterIconRetina.png 2x" alt="Twitter" /></a></li>
                        <li><a href="#"><img src="images/PinterestIcon.png" srcset="images/PinterestIconRetina.png 2x" alt="Pinterest" /></a></li>
                        <li><a href="#"><img src="images/VimeoIcon.png" srcset="images/VimeoIconRetina.png 2x" alt="Vimeo" /></a></li>
                    </ul>
                </div>-->
            </div>
            <!-- <div class="TopSwitch"><a href="#PageTop"><img src="/images/TopSwitchIcon.png" srcset="/images/TopSwitchIconRetina.png 2x" alt=""/></a></div> -->
        </div>
    </div>
</footer>
<!-- Fine footer --><!-- Attenzione: l'ultima riga deve esserci! -->
</template>
<script>

import moment from "moment"
import MenuService from '@/services/menu.service.js';
import NewsletterService from '@/services/newsletter.service.js';
import Menu from '@/models/Menu.model.js';
import {EmailMixin, risorseMixin} from "@/mixin.js";
import IscrizioneNewsletter from "@/models/IscrizioneNewsletter.model.js";

export default {
    name: "Footer",
    data(){
        return {
            linkFooter1: [],
            linkFooter2: [],
            linkFooter3: [],
            privacyChecked: false,
            emailNewsletter: "",
            showNewslettersMsg: false,
            newsletterMsg: ""
        }
    },
    mixins: [EmailMixin, risorseMixin],
    methods: {
        goTop: function(){
            window.scrollTo(0, 0);
        },
        subscribeNewsletter: function(){
            this.resetMsgNewsletter();
            if(!this.privacyChecked){
                this.newsletterMsg = this.risorse["NewsPriMsg"];
                this.showNewslettersMsg = true;
                window.setTimeout(this.resetMsgNewsletter, 4000);
            }
            else if(!this.validateEmail(this.emailNewsletter)){
                this.newsletterMsg = this.risorse["NewsValidEmail"];
                this.showNewslettersMsg = true;
                window.setTimeout(this.resetMsgNewsletter, 4000);
            } 
            else{
                let iscrizione = new IscrizioneNewsletter();              
                iscrizione.CodiceCliente = this.$store.getters.getAnagraficaAzienda.CodiceCliente;
                iscrizione.EmailIscrizione = this.emailNewsletter;
                iscrizione.DataRichiestaIscrizione = moment().format("YYYY-MM-DD HH:mm:ss");
                iscrizione.PrivacyPolicy = this.privacyChecked ? 1 : 0;
                NewsletterService. subscribeNewsletter(this.$store.getters.getToken, iscrizione).then(
                    (data) => {                        
                        if(!data){
                            this.$router.push("/")
                        }
                        else{
                            if(data.Status){
                                this.newsletterMsg = this.risorse["NewsOk"];
                                this.privacyChecked = false;
                                this.emailNewsletter = "";
                            }
                            else{
                                this.newsletterMsg = this.risorse["NewsEmailEsiste"];
                            }
                            
                            this.showNewslettersMsg = true;
                            window.setTimeout(this.resetMsgNewsletter, 4000);             
                        }               
                    }
                ).catch(
                    (err) => {console.log(err)}
                )                
            }
        },
        resetMsgNewsletter: function(){            
            this.showNewslettersMsg = false;
            this.newsletterMsg = "";
        },
        getFooterMenu(tipoMenu){
            // MenuService.getMenuByTipo(this.$store.getters.getToken, tipoMenu, this.$store.getters.getLingua, "B2B").then(
            //     (data) => {                        
            //         if(!data){
            //             this.$router.push("/")
            //         }
            //         else{
            //             let menu = data.map(m => {
            //                 let link = new Menu();
            //                 return Object.assign(link, m);
            //             });                
            //             if(tipoMenu == "FOOTER1")this.linkFooter1 = menu;
            //             if(tipoMenu == "FOOTER2")this.linkFooter2 = menu;
            //             if(tipoMenu == "FOOTER3")this.linkFooter3 = menu;                        
            //         }               
            //     }
            // ).catch(
            //     (err) => {console.log(err)}
            // )
        }
    },
    mounted: function(){
        this.getFooterMenu("FOOTER1");
        this.getFooterMenu("FOOTER2");
        this.getFooterMenu("FOOTER3");

    }
}
</script>
<style>
    .NewsletterMsg{
        color: #ff0000;
        margin-top:20px;
        font-size: 13px;
    }
</style>