import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL;

class NewsletterService{    

    async subscribeNewsletter(token, iscrizione){
        
        return axios({
            method: 'post',
            url: API_URL + 'IscrizioneNewsletter/InsertIscrizioneNewletter',           
            data: iscrizione,
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }
        }).then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data;
                }              
            }
        )
        .catch(error => {return error;});
    }
}

export default new NewsletterService();